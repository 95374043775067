import Vue from 'vue'
import VueRouter from "vue-router";
Vue.use(VueRouter);
// 引入路由组件
// 使用的路由懒加载
const router = new VueRouter({
  // mode: 'history',
  routes: [{
    path: "",
    redirect: "/index"
  },
  {
    /* 首页 */
    path: "/index",
    // 路由懒加载
    component: () => import('@/views/index/index.vue'),
    meta: {
      show: false
    }
  },
  {
    /* 解决方案 */
    // 数据资产入表
    path: "/dasolution",
    component: () => import('@/views/solution/dataAssets.vue'),
    meta: {
      show: true
    }
  }, {
    /* 解决方案 */
    // 数据资产管理
    path: "/damainsolution",
    component: () => import('@/views/solution/damainsolution.vue'),
    meta: {
      show: true
    }
  },
  {
    /* 解决方案 */
    // 智能溺水监测预警系统
    path: "/swimsolution",
    component: () => import('@/views/solution/swimsolution.vue'),
    meta: {
      show: true
    }
  },
  {
    /* 解决方案 */
    // 行业大模型
    path: "/imsolution",
    component: () => import('@/views/solution/iargeModel.vue'),
    meta: {
      show: true
    }  
  }, {
    // 智能地理绘图
    path: "/gmsolution",
    component: () => import('@/views/solution/geographicMapping.vue'),
    meta: {
      show: true
    }
  },
  /* 产品系列 */
  /* 智能溺水监测预警系统*/
  {
    path: "/swimstystem",
    component: () => import('@/views/product/swimstystem.vue'),
    meta: {
      show: true
    }
  }, {
    /* 金融 */
    path: "/finance",
    component: () => import('@/views/product/finance.vue'),
    meta: {
      show: true
    }
  }, {
    /* 医药 */
    path: "/medical",
    component: () => import('@/views/product/medical.vue'),
    meta: {
      show: true
    }
  }, {
    /* 智能地理 */
    path: "/gis",
    component: () => import('@/views/product/gis.vue'),
    meta: {
      show: true
    }
  }, {
    /* 数据资产 */
    path: "/sjzc",
    component: () => import('@/views/product/sjzc.vue'),
    meta: {
      show: true
    }
  }, {
    /* 分级分类 */
    path: "/classification",
    component: () => import('@/views/product/classification.vue'),
    meta: {
      show: true
    }
  }, {
    // 关于我们
    path: "/aboutus",
    component: () => import('@/views/about/aboutUs.vue'),
    meta: {
      show: true
    }
  },
  {
    // 关于我们
    path: "/newsDetail",
    component: () => import('@/views/index/newsDetail.vue'),
    meta: {
      show: true
    }
  },
  ],
  // vuerouter滚动行为,每次加载时回到顶部
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return {
      y: 0
    }
  },
});
// 解决vue编程式导航多次点击抛出的错误
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router

