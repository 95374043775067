<!--  -->
<template>
    <div class="footr">
        <div class="content">
            <div class="lst">
                <img style="width: 1.87rem;height: .61rem;margin: 0 0 .18rem 0;" src="@/assets/images/logo2.png" alt="">
                <p class="dan">
                    天清数安（天津）科技有限公司
                </p>
                <p class="dan">
                    地址：天津市南开区天开高教园5号楼1104
                </p>
                <p class="dan">
                    邮箱：contact@tqds.com.cn
                </p>
                <p class="dan">
                    联系电话：022-27366066
                </p>
            </div>
            <div class="lst">
                <p class="dan">
                    解决方案
                </p>
                <p class="shuang shuangactive" @click="$router.push('/damainsolution')">
                    数据资产管理解决方案
                </p>
                <p class="shuang shuangactive" @click="$router.push('/dasolution')">
                    数据资产入表解决方案
                </p>
                <p class="shuang shuangactive" @click="$router.push('/swimsolution')">
                    水上安全智能解决方案
                </p>
                <p class="shuang shuangactive" @click="$router.push('/imsolution')">
                    行业大模型解决方案
                </p>
                <p class="shuang shuangactive" @click="$router.push('/gmsolution')">
                    智能地理绘图解决方案
                </p>
            </div>
            <div class="lst">
                <p class="dan">
                    产品系列
                </p>
                <p class="shuang shuangactive" @click="$router.push('/sjzc')">
                    智能全周期数据资产安全管理平台
                </p>
                <p class="shuang shuangactive" @click="$router.push('/classification')">
                    数据分级分类系统
                </p>
                <p class="shuang shuangactive" @click="$router.push('/swimstystem')">
                    天澜智能溺水监测预警系统
                </p>
                <p class="shuang shuangactive" @click="$router.push('/finance')">
                    金融行业大模型应用平台
                </p>
                <p class="shuang shuangactive" @click="$router.push('/medical')">
                    医疗大模型应用平台
                </p>
                <p class="shuang shuangactive" @click="$router.push('/gis')">
                    智能地理绘图系统
                </p>
            </div>
            <div class="lst">
                <p class="dan">
                    关于我们
                </p>
                <p class="shuang shuangactive" @click="$router.push('/aboutus')">
                    知识产权
                </p>
                <p class="shuang shuangactive" @click="$router.push('/aboutus')">
                    联系我们
                </p>
                <p class="shuang shuangactive" @click="$router.push('/aboutus')">
                    企业风采
                </p>

            </div>
            <div class="lst lst3">
                <div class="dan" style="margin-left: .4rem;">
                    联系我们
                </div>
                <div class="lst2_box">

                    <div class="lst2">
                        <div class="di">
                            <img src="@/assets/images/wxchart.png">
                        </div>
                        <p class="dan" style="font-size:.12rem;opacity: 0.9;text-align: center;margin: .05rem 0 0 0;">
                            添加微信公众号
                        </p>
                    </div>
                    <div class="lst2">
                        <div class="di">
                            <img src="@/assets/images/wxchart2.png">
                        </div>
                        <p class="dan" style="font-size:.12rem;opacity: 0.9;text-align: center;margin: .05rem 0 0 0;">
                            添加客服企业微信
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="codes">
            <div class="text">
                <span @click="href(0)" style="margin: 0 .2rem;">
                    津ICP备2024022784号-2
                </span>
                <span @click="href(1)">
                    津公网安备12010402002151号
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            scrollTop: 0,
            img: false
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        showWeChatImg() {
            let WeChatImg = this.$refs.WeChatImg
            WeChatImg.classList.add('activemouseenter');
            WeChatImg.classList.remove('activemouseleave');
            this.img = true

        },
        hideWeChatImg() {
            let WeChatImg = this.$refs.WeChatImg
            // WeChatImg.classList.add('activemouseleave');
            // WeChatImg.classList.remove('activemouseenter');
            this.img = false
        },
        href(e) {
            if (e == 0) {
                window.open('https://beian.miit.gov.cn')
            } else if (e == 1) {
                window.open('https://beian.mps.gov.cn')
            }
        },
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() {

    }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.footr {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 0rem 0.2rem;
    background-color: rgba(51, 54, 56, 1) !important;
}
.footr .content {
    width: 12rem;
    height: 100%;
    margin: 0rem auto;
    display: flex;
    flex-wrap: wrap;
}
.footr .content .lst {
    margin-right: 0.55rem;
}
.footr .content .lst:nth-child(1) {
    margin-top: -0.3rem;
}
.footr .content .lst .dan {
    font-size: 0.15rem;
    color: rgba(255, 255, 255, 1);
    margin: 0 0 0.14rem 0;
}

.footr .content .lst .shuang {
    font-size: 0.14rem;
    color: #ffffff;
    line-height: 0.35rem;
}
.shuangactive {
    &:hover {
        cursor: pointer;
        color: #8f8dec !important;
        text-decoration: dotted;
        text-decoration: underline;
    }
}
.footr .content .lst:nth-child(2),
.footr .content .lst:nth-child(3),
.footr .content .lst:nth-child(4) {
    .shuang {
        color: rgba(153, 153, 153, 1);
    }
}
.footr .content .lst .lst2 {
    margin-left: 0.2rem;
}
.lst2 .di {
    width: 1.01rem;
    height: 1.01rem;
    img {
        width: 100%;
        height: 100%;
    }
}
.lst2_box {
    display: flex;
    margin: 0 0 0 0.2rem;
}
.footr .content .lst3 {
    width: 2.68rem;
    margin-right: 0rem;
    border-left: 0.01rem solid #bbb;
}
.footr .content .lst3 .di {
    margin-top: 0.23rem;
}
.codes {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 0 0;
    .text {
        width: 12rem;
        height: 0.4rem;
        font-weight: 400;
        font-size: 0.14rem;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
    }
}
</style>
