<!--  -->
<template>
    <div class='header' ref="scroll">
        <div class="content " ref="content">
            <!-- 导航 -->
            <transition name="fade">
                <div class="nav_content w1140" v-show="navAndSearch === 0">
                    <div class="logo" @click="$router.push('/index')">
                        <img class="header_logo" src="@/assets/images/logotop.png" alt="天清数安">
                    </div>
                    <div class="header_right">
                        <div ref="nav_detail" class="header_nav fs-14" v-show="showMenu === 0">
                            <ul class="header_nav_content">
                                <li class="nav_item" @mouseover="showHoverClick(list, index)" @mouseleave="cloneHoverClick(index)" v-for="(list, index) in navList" :key="list.id">
                                    <span ref="item_text" :class="['item_text',tabactive == index ? 'itemtextactive' : '']" href="###" @click="navTarget(list,index)">
                                        {{ list.title }}
                                        <img :class="['arrow-icon', {'rotate': list.flag}]" v-if="index == 1 || index == 2" src="@/assets/public/icons_bottom.png" alt="">
                                    </span>
                                    <transition name="expand">
                                        <div ref="menu" v-if="list.flag" class="subnav-box" :style="{minWidth:index == 1 ? '1.9rem' :index == 2 ? '2.6rem' : '' }" v-show="list.childrenList.length > 0">
                                            <ul ref="subnav_menu" class="subnav-menu">
                                                <li ref="menu_item" class="menu_item" @click="solutionchange(item,index,sonindex)" v-for="(item, sonindex) in list.childrenList" :key="item.id">
                                                    {{ item.chilTitle }}
                                                </li>
                                            </ul>
                                        </div>
                                    </transition>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </transition>
            <!-- 搜索 -->
            <transition name="fade">
                <div class="search" v-show="navAndSearch === 1">
                    <div class="serach_box w1140">
                        <div class="search_cont">
                            <input type="text" placeholder="Search..." class="input">
                            <i class="el-icon-search"></i>
                        </div>

                        <div class="search_button" @click="cloneSearch">
                            <i class="el-icon-close"></i>
                        </div>
                    </div>

                </div>
            </transition>
        </div>
        <!-- 返回顶部 -->
        <div v-if="showTop" ref="scroll" class="up_arrow_buttom" @click="getScollTop">
            <img src="@/assets/images/topup_arrow.png" alt="">
        </div>
        <div ref="scroll" class="up_arrow_buttom up_arrow_buttom2" @click="showkefu">
            <img src="@/assets/images/message.png" alt="">
        </div>

        <transition name="slide-fade">
            <KeFu @close="handleClose" v-show="showkefuvisible" />
        </transition>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import KeFu from '@/components/kefu.vue'

export default {
    //import引入的组件需要注入到对象中才能使用
    components: { KeFu },
    props: ['tabactivetan'],
    data() {
        //这里存放数据
        return {
            showkefuvisible: false,
            showTop: false,
            tabactive: 0,
            navAndSearch: 0,// 导航与搜索的切换标识
            showMenu: 0, // 菜单切换标识 ， 0 隐藏
            showDetail: 0, // 显示ul标识
            flag: false, // 大于1024时显隐ul
            // 头部数据
            navList: [
                {
                    id: 1,
                    title: "首页",
                    flag: false,
                    childrenList: []
                },

                {
                    id: 2,
                    title: "解决方案",
                    flag: false,
                    childrenList: [
                        {
                            id: 1,
                            chilTitle: '数据资产管理解决方案',
                        }, {
                            id: 2,
                            chilTitle: '数据资产入表解决方案',
                        },
                        {
                            id: 3,
                            chilTitle: '水上安全智能解决方案',
                        }, {
                            id: 4,
                            chilTitle: '行业大模型解决方案',
                        }, {
                            id: 5,
                            chilTitle: '智能地理绘图解决方案',
                        },
                    ]

                },
                {
                    id: 3,
                    title: "产品系列",
                    flag: false,
                    childrenList: [
                        {
                            id: 1,
                            chilTitle: ' 智能全周期数据资产安全管理平台',
                        },
                        {
                            id: 2,
                            chilTitle: ' 数据分级分类系统',
                        }, {
                            id: 3,
                            chilTitle: ' 天澜智能溺水监测预警系统',
                        },
                        {
                            id: 4,
                            chilTitle: ' 金融行业大模型应用平台',
                        }, {
                            id: 5,
                            chilTitle: '医疗大模型应用平台',
                        }, {
                            id: 6,
                            chilTitle: '智能地理绘图系统',
                        },
                    ]
                },
                {
                    id: 4,
                    title: "关于我们",
                    flag: false,
                    childrenList: []
                },
            ],
            // 表单校验
            rules: {
                gbCode: [
                    { required: true, message: '标准编码不能为空', trigger: 'blur' }
                ],
                gbName: [
                    { required: true, message: '标准名称不能为空', trigger: 'blur' }
                ]
            },

        };
    },
    computed: {
    },
    //监控data中的数据变化
    watch: {
    },
    //方法集合
    methods: {
        handleClose() {
            this.showkefuvisible = false
        },
        showkefu() {
            this.showkefuvisible = !this.showkefuvisible
        },
        // 回到顶部
        getScollTop(e) {
            let top = document.documentElement.scrollTop || document.body.scrollTop;// 实现滚动效果
            const timeTop = setInterval(() => { document.body.scrollTop = document.documentElement.scrollTop = top -= 50; if (top <= 0) { clearInterval(timeTop); } }, 5);
        },
        // 关闭搜索界面
        cloneSearch() {
            this.navAndSearch = 0;
        },
        showHoverClick(list, index) {
            let navtext = this.$refs.item_text
            // let menu_item = this.$refs.menu_item
            // let subnav_menu = this.$refs.subnav_menu

            this.navList.forEach(item => {
                return item.flag = false
            })
            this.navList[index].flag = true
            if (this.$store.state.user.clientWidth > 1024) {
                navtext[index].style.borderBottom = '.02rem solid #085bf9'
            }

        },
        cloneHoverClick(index) {
            let menu_item = this.$refs.menu_item
            let subnav_menu = this.$refs.subnav_menu
            let navtext = this.$refs.item_text
            this.navList[index].flag = false
            if (this.$store.state.user.clientWidth > 1024) {
                navtext[index].style.borderBottom = 'none'
            }
        },
        // 导航跳转
        navTarget(data, index) {
            switch (data.id) {
                case 1:
                    this.$router.push('/index')
                    break;
                case 2:
                    this.$router.push('/damainsolution')
                    this.navList[index].title = data.childrenList[0].chilTitle
                    break;
                case 3:
                this.$router.push('/sjzc')
                    break;
                case 4:
                    this.$router.push('/aboutus')
                    break;
                default:
                    break;
            }
            this.tabactive = index

            console.log(index);

            // // 保存选中的标题到 localStorage
            // if (index === 1) {
            //     localStorage.setItem('selectedTitle1', data.childrenList[0].chilTitle);
            // } else if (index === 2) {
            //     localStorage.setItem('selectedTitle2', data.childrenList[0].chilTitle);
            // }
        },
        solutionchange(item, index, sonindex) {
            /* 解决方案 */
            if (index == 1) {
                switch (sonindex) {
                    case 0:
                        this.$router.push('/damainsolution')
                        break;
                    case 1:
                        this.$router.push('/dasolution')
                        break;
                    case 2:
                        this.$router.push('/swimsolution')
                        break;
                    case 3:
                        this.$router.push('/imsolution')
                        break;
                    case 4:
                        this.$router.push('/gmsolution')
                        break;
                    default:
                        break;
                }
                this.tabactive = 1
                this.navList[1].title = item.chilTitle
                // localStorage.setItem('selectedTitle1', item.chilTitle);
            } else if (index == 2) {
                /* 产品系列 */
                switch (sonindex) {
                    case 0:
                        this.$router.push('/sjzc')
                        break;
                    case 1:
                        this.$router.push('/classification')
                        break;
                    case 2:
                        this.$router.push('/swimstystem')
                        break;
                    case 3:
                        this.$router.push('/finance')
                        break;
                    case 4:
                        this.$router.push('/medical')
                        break;
                    case 5:
                        this.$router.push('/gis')
                        break;
                    default:
                        break;
                }
                this.tabactive = 2
                this.navList[2].title = item.chilTitle
                // localStorage.setItem('selectedTitle2', item.chilTitle);
            }
        },
        handleScroll() {
            const item = this.$refs.content;
            const scrollY = window.scrollY;
            if (scrollY === 0) {
                item.style.background = 'rgba(255, 255, 255, 0.37)'; // 更改背景颜色
            } else {
                item.style.background = '#ffffff'; // 更改背景颜色
                item.style.backgroundColor = 'rgba(255, 255, 255, 1)';
            }
            this.showTop = scrollY > 1080 ? true : false
            this.getheaderHeight()
        },
        getheaderHeight() {
            let dom = this.$refs.content;  // 使用 ref 代替 querySelector
            this.$bus.$emit("headerHeight", dom.offsetHeight);
        }
    },
    beforeCreate() {
        // this.getClientWidth()
    }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        // 绑定滚动事件
        window.addEventListener('scroll', this.handleScroll);
        this.getheaderHeight();

        this.tabactive = this.tabactivetan;

        // 添加以下代码以保留上次选中的标题
        // this.navList[1].title = localStorage.getItem('selectedTitle1') || this.navList[1].title;
        // this.navList[2].title = localStorage.getItem('selectedTitle2') || this.navList[2].title;
    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() {
        // window.removeEventListener('resize', this.getClientWidth);
        // 移除滚动事件
        window.removeEventListener('scroll', this.handleScroll);
    }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
a {
    text-decoration: none;
}

.fs-14 {
    font-size: 0.14rem;
    line-height: 2;
}

.w1140 {
    max-width: 12.5rem;
    margin-left: auto;
    margin-right: auto;
}

.fw-700 {
    font-weight: 700;
}

.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;

    .content {
        display: flex;
        align-items: center;
        background: rgba(255, 255, 255, 0.37);
    }

    .nav_content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            width: 2rem;
            height: 0.48rem;
            cursor: pointer;
            // margin: 0 0 0 .5rem;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.07rem;
            }
        }

        .header_right {
            display: flex;
            align-items: center;

            .header_nav {
                .nav_item {
                    display: flex;
                    align-items: center;
                    position: relative;
                    .item_text {
                        font-weight: 400;
                        font-size: 0.18rem;
                        color: #373738;
                        margin: 0 0 0 0.62rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        padding-bottom: 0.05rem;
                    }
                    .itemtextactive {
                        color: rgba(10, 92, 255, 1);
                        // border-bottom: 0.02rem solid #321dd5;
                    }

                    .subnav-box {
                        position: absolute;
                        background-color: rgba(255, 255, 255, 0.9);
                        backdrop-filter: blur(0.05rem);
                        margin-left: 0.62rem;
                        top: 0.8rem;
                        left: 0;
                        border-radius: 0.04rem;
                        box-shadow: 0 0.02rem 0.12rem rgba(0, 0, 0, 0.1);

                        .subnav-menu {
                            .menu_item {
                                font-size: 0.15rem;
                                display: block;
                                padding: 0.03rem 0.15rem;

                                a {
                                    text-decoration: none;

                                    &:hover {
                                        color: #4eadd3;
                                    }
                                }
                            }
                            .menu_item:hover {
                                color: #2722f8;
                                cursor: pointer;
                                // background: rgba(255, 255, 255, 0.37);
                            }
                        }
                    }
                }
            }

            .icons_button {
                font-size: 0.24rem;

                .chat_icon {
                    width: 0.3rem;
                    height: 0.3rem;
                    margin: auto;
                    line-height: 0.3rem;
                    text-align: center;
                    display: inline-block;
                    color: #ffffff;
                    background-color: #085bf9;
                    border-radius: 50%;
                }

                .search_icon {
                    display: inline-block;
                    margin-left: 0.15rem;

                    .el-icon-search {
                        color: #00a4ff;
                        font-weight: 800;
                    }
                }

                .menu_icon {
                    margin-left: 0.15rem;
                    line-height: 0.3rem;
                    text-align: center;
                    display: inline-block;
                    color: #085bf9;
                }
            }
        }
    }

    .search {
        width: 100%;
        background-color: #397eec;
        border-top: 0.01rem solid #ffffff;

        .serach_box {
            display: flex;
            justify-content: space-between;

            .search_cont {
                display: flex;
                width: 80%;
                border-bottom: 0.01rem solid #ffffff;

                .input {
                    width: 100%;
                    color: #ffffff;
                    background-color: #397eec;
                    margin-bottom: 0.05rem;

                    &::placeholder {
                        color: #ffffff;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                i {
                    font-size: 0.24rem;
                    color: #ffffff;
                    margin-bottom: 0.05rem;
                }
            }

            .search_button {
                margin-left: 0.5rem;

                i {
                    font-size: 0.24rem;
                    color: #ffffff;
                }
            }
        }
    }
}

.chat_Form {
    margin-top: 2%;

    .from_title {
        color: #303133;
        font-weight: 800;
        text-align: center;
    }

    .from_cont {
        background-color: #ffffff;
        display: contents;

        .form_item {
            width: 100%;

            .el-form-item__content {
                width: 100%;

                .itme_input {
                    border: solid 0.01rem #ffffff;
                    width: 100%;
                    font-size: 0.18rem;
                    font-weight: 500;
                    color: rgba(17, 17, 17, 0.5);
                }
            }
        }

        ::v-deep .el-form-item__content {
            width: 95%;
            line-height: 0.5rem;
        }

        ::v-deep .el-input__inner {
            background-color: #f2f4f8;
            height: 0.52rem;
        }

        ::v-deep .el-textarea__inner {
            width: 100%;
            background-color: #f2f4f8;
        }

        ::v-deep .el-form-item--small.el-form-item {
            margin: 0.09rem 0.06rem;
        }
    }

    .submit_box {
        display: flex;
        justify-content: center;

        .submit {
            width: 1.5rem;
            border: 0.01rem solid #397eec;
            font-size: 0.16rem;

            &:hover {
                background-color: #397eec;
                color: #ffffff;
            }
        }
    }
}

// 媒体查询
@media (min-width: 10.24rem) {
    .header .nav_content {
        width: 17rem;
    }

    .header .logo {
        padding-left: 0.2rem;
    }

    .header .header_nav .header_nav_content {
        height: 0.8rem;
        display: flex;
        align-items: center;
    }

    .header .header_nav .nav_item {
        height: 0.8rem;
    }

    .chat_Form {
        ::v-deep .el-dialog {
            width: 66%;
        }
    }

    .from_title {
        height: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.4rem;
    }

    .from_cont {
        padding: 0.64rem;
    }
}

@media (max-width: 10.24rem) {
    .header .nav_content {
        padding: 0.1rem 0.15rem;
    }

    .header .logo img {
        margin-right: 1rem;
    }

    .header .header_right .header_nav {
        position: fixed;
        width: 100%;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0.54rem;
        background-color: #ffffff;
        padding: 0.36rem 0.24rem 0.2rem 0.24rem;
        z-index: 99;
    }

    .header .header_right .nav_item {
        margin-left: 0.2rem;

        .item_text {
            font-size: 0.2rem;
            color: #666666;
        }

        .subnav-box {
            display: none;
        }
    }

    .chat_Form {
        ::v-deep .el-dialog {
            width: 93%;
        }
    }

    .from_title {
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.34rem;
    }

    .from_cont {
        padding: 0.11rem;
    }
}

// 返回顶部
.up_arrow_buttom {
    position: fixed;
    background-color: #085bf9;
    border-radius: 50%;
    width: 0.45rem;
    height: 0.45rem;
    z-index: 9999;
    right: 0.2rem;
    bottom: 2rem;

    img {
        display: inline-flex;
        position: absolute;
        width: 0.25rem;
        height: 0.25rem;
        top: 25%;
        left: 23%;
    }
}
.up_arrow_buttom2 {
    position: fixed;
    background-color: #085bf9;
    border-radius: 50%;
    width: 0.45rem;
    height: 0.45rem;
    z-index: 9999;
    right: 0.2rem;
    bottom: 1.4rem;
    cursor: pointer;
}

// 修改过渡动画样式
.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: translateY(-0.2rem);
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
    transform: translateY(0);
}

// 确保导航内容在动画期间保持可见
.nav_content {
    position: relative;
    width: 100%;
}

.search {
    position: relative;
    width: 100%;
}

// 客服组件的过渡动画
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(0.2rem);
    opacity: 0;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
    transform: translateX(0);
    opacity: 1;
}

// 添加箭头图标的旋转动画
.arrow-icon {
    width: 0.13rem;
    height: 0.13rem;
    margin: 0 0 -0.01rem 0.05rem;
    transform: rotate(270deg);
    transition: transform 0.3s ease;
}

.arrow-icon.rotate {
    transform: rotate(360deg);
}

// 添加子菜单展开动画
.expand-enter-active,
.expand-leave-active {
    transition: all 0.3s ease;
    overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
    opacity: 0;
    transform: translateY(-0.1rem);
}

.subnav-box {
    transform-origin: top;
    transition: all 0.3s ease;
}
</style>
