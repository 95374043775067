import Vue from 'vue'
import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
/* 字体引入 */
import "@/assets/font/font.css"
import "@/assets/style/index.css"
/* video.js */
import Videojs from 'video.js'
import 'video.js/dist/video-js.css'

import 'ol/ol.css';


Vue.prototype.$video = Videojs
import VueLazyload from 'vue-lazyload'

// Vue.prototype.$baseUrl = 'http://192.168.110.128:9001'
Vue.prototype.$baseUrl = 'https://192.144.213.239:9001',

    // vue 2 注册
    Vue.use(VueLazyload, {
        //参数配置 可不填

        // 懒加载默认加载图片
        loading: '',
        // 加载失败后加载的图片
        error: '',
        preLoad: 1.3, // 预加载高度的比例
        attempt: 3 // 尝试加载次数
    })


Vue.config.productionTip = false

Vue.use(ElementUi, {size: 'small', zIndex: 3000});

new Vue({
    router,
    store,
    beforeCreate() {
        Vue.prototype.$bus = this
    },
    render: h => h(App)
}).$mount('#app')
