<template>
    <div class="chat-container">
        <div class="chat-header">
            <h2>天清数安助手</h2>
            <div class="header-controls">
                <button class="control-btn minimize" @click="minimize">－</button>
                <button class="control-btn close" @click="close">×</button>
            </div>
        </div>

        <div class="chat-messages" ref="chatMessages">
            <div v-for="(msg, index) in messages" :key="index" :class="['message', msg.isUser ? 'user-message' : 'bot-message']">
                <div class="message-bubble">
                    <div class="avatar" :style="getAvatarStyle(msg.isUser)"></div>
                    <div class="message-content" v-html="msg.isUser ? msg.content : marked(msg.content)"></div>
                </div>
                <div class="message-time">{{ msg.time }}</div>
            </div>
        </div>

        <div class="input-area">
            <div class="quick-replies">
                <div v-for="(category, idx) in questions" :key="idx" class="category-group">
                    <div class="question-category">{{ category.category }}</div>
                    <div class="button-group">
                        <button v-for="(question, qIdx) in category.items" :key="qIdx" @click="quickReply(question)">
                            {{ question }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="input-group">
                <input type="text" v-model="userInput" placeholder="请输入您的问题..." @keypress.enter="sendMessage" autocomplete="off">
                <button class="send-button" @click="sendMessage">发送</button>
            </div>
        </div>
    </div>
</template>

<script>
import { marked } from 'marked'

export default {
    name: 'KeFu',

    data() {
        return {
            userInput: '',
            messages: [],
            questions: [
                {
                    category: "产品咨询",
                    items: ["产品功能介绍", "产品价格咨询",]
                },
                {
                    category: "技术支持",
                    items: ["系统使用教程", "技术支持时间", "如何联系客服？",]
                }
            ],
            autoReplies: {
                "产品价格咨询": `# 产品价格方案

| 版本 | 功能特点 | 价格 | 适用对象 |
|------|----------|------|----------|
| 基础版 | 基础功能 | ￥***/月 | 个人用户 |
| 专业版 | 全部功能 | ￥*** | 企业用户 |
| 定制版 | 定制开发 | 详询客服 | 大型企业 |

> 现在注册即可享受优惠价格`,

                "产品功能介绍": `# 产品功能介绍

1. 基础功能
   - 数据分析
   - 报表导出
   - 用户管理

2. 高级功能
   - 智能预测
   - 自动化处理
   - API 接口

> 可以根据您的具体需求选择合适的版本`,

                "技术支持时间": `# 技术支持服务时间

- 在线支持：周一至周日 9:00-21:00
- 电话支持：工作日 9:00-18:00
- 邮件支持：24小时

> 紧急问题请拨打技术支持热线：022-27366066`,

                "如何联系客服？": `# 联系方式

1. 客服热线：022-27366066
2. 邮件支持：tianqingshuan2023@163.com
3. 在线客服：工作日 9:00-18:00

> 建议优先使用在线客服，可以更快获得响应`,

                "系统使用教程": `# 系统使用教程

1. 新手入门
   - 账号注册
   - 基础设置
   - 功能介绍

2. 进阶使用
   - 高级功能
   - 最佳实践
   - 常见问题

> 详细教程请访问帮助中心：***`,

                "default": `抱歉，我可能没有理解您的问题。建议您：

1. 换个方式描述问题
2. 查看常见问题分类
3. 联系人工客服

客服热线：022-27366066`
            }
        }
    },

    mounted() {
        this.initChat()
        marked.setOptions({ breaks: true })
    },

    methods: {
        marked(content) {
            return marked(content)
        },

        initChat() {
            const welcomeMessage = `# 您好！我是天清数安助手 👋
  
  很高兴为您服务，请问有什么可以帮您？
  
  ## 常见问题分类
  2. 产品咨询
     - 产品价格咨询
     - 产品功能介绍
  
  3. 技术支持
     - 技术支持时间
  
  > 温馨提示：在线客服 24 小时为您服务`

            this.addMessage(welcomeMessage, false)
        },

        addMessage(content, isUser) {
            this.messages.push({
                content,
                isUser,
                time: new Date().toLocaleTimeString('zh-CN', {
                    hour: '2-digit',
                    minute: '2-digit'
                })
            })
            this.$nextTick(() => {
                this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight
            })
        },

        getAvatarStyle(isUser) {
            const userAvatar = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6bTAgM2MxLjY2IDAgMyAxLjM0IDMgM3MtMS4zNCAzLTMgMy0zLTEuMzQtMy0zIDEuMzQtMyAzLTN6bTAgMTQuMmMtMi41IDAtNC43MS0xLjI4LTYtMy4yMi4wMy0xLjk5IDQtMy4wOCA2LTMuMDggMS45OSAwIDUuOTcgMS4wOSA2IDMuMDgtMS4yOSAxLjk0LTMuNSAzLjIyLTYgMy4yMnoiLz48L3N2Zz4="
            const botAvatar = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iIzE5NzZEMiIgZD0iTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6bTAgMThjLTQuNDEgMC04LTMuNTktOC04czMuNTktOCA4LTggOCAzLjU5IDggOC0zLjU5IDgtOCA4em0wLTEyLjVjLTIuNDkgMC00LjUgMi4wMS00LjUgNC41czIuMDEgNC41IDQuNSA0LjUgNC41LTIuMDEgNC41LTQuNS0yLjAxLTQuNS00LjUtNC41em0wIDUuNWMtLjU1IDAtMS0uNDUtMS0xczAtLjQ1IDEtLjQgMS40NSAxIDEgMS0uNDUgMS0xIDF6Ii8+PC9zdmc+"

            return {
                backgroundImage: `url('${isUser ? userAvatar : botAvatar}')`
            }
        },

        quickReply(question) {
            this.userInput = question
            this.sendMessage()

        },

        sendMessage() {
            const message = this.userInput.trim()
            if (message) {
                this.addMessage(message, true)
                this.userInput = ''

                setTimeout(() => {
                    let response = this.autoReplies[message] || this.autoReplies.default

                    if (!this.autoReplies[message]) {
                        for (let key in this.autoReplies) {
                            if (message.includes(key) && key !== 'default') {
                                response = this.autoReplies[key]
                                break
                            }
                        }
                    }

                    this.addMessage(response, false)
                }, 500)
            }
        },
        minimize() {
            this.$emit('close')
        },
        close() {
            this.$emit('close')
        },
    }
}
</script>

<style scoped>
/* 复制之前的所有 CSS 样式到这里 */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.chat-container {
    width: 5rem;
    height: 75vh;
    background: white;
    border-radius: 0.12rem;
    box-shadow: 0 0.05rem 0.15rem rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0.8rem;
    bottom: 0.5rem;
    z-index: 999;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.12rem 0.16rem;
    background: linear-gradient(90deg, #2196f3, #1976d2);
    color: white;
    border-radius: 0.12rem 0.12rem 0 0;
}

.chat-header h2 {
    font-size: 0.16rem;
    font-weight: 500;
}

.chat-messages {
    flex: 1;
    padding: 0.16rem;
    overflow-y: auto;
    background: #f8f9fa;
}

.message {
    margin-bottom: 0.16rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.message-bubble {
    display: flex;
    align-items: flex-start;
    gap: 0.08rem;
}

.user-message .message-bubble {
    flex-direction: row-reverse;
}

.avatar {
    width: 0.32rem;
    height: 0.32rem;
    border-radius: 50%;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
    border: 0.02rem solid #fff;
    box-shadow: 0 0.02rem 0.04rem rgba(0, 0, 0, 0.1);
}

.message-content {
    padding: 0.1rem 0.14rem;
    border-radius: 0.12rem;
    font-size: 0.13rem;
    line-height: 1.5;
    box-shadow: 0 0.01rem 0.02rem rgba(0, 0, 0, 0.1);
}

.bot-message .message-content {
    background: white;
    border-bottom-left-radius: 0.04rem;
    max-width: 85%;
}

.user-message .message-content {
    background: #1976d2;
    color: white;
    border-bottom-right-radius: 0.04rem;
    max-width: 70%;
}

.message-time {
    font-size: 0.11rem;
    color: #999;
    margin: 0.02rem 0.4rem;
}

.user-message .message-time {
    text-align: right;
}

.input-area {
    padding: 0.12rem 0.16rem;
    background: white;
    border-top: 0.01rem solid #eee;
}

.input-group {
    display: flex;
    align-items: center;
    gap: 0.1rem;
    background: #f8f9fa;
    padding: 0.06rem;
    border-radius: 0.24rem;
    border: 0.01rem solid #e0e0e0;
}

.input-group:focus-within {
    border-color: #1976d2;
    box-shadow: 0 0 0 0.02rem rgba(25, 118, 210, 0.1);
}

.input-group input {
    flex: 1;
    padding: 0.08rem 0.16rem;
    border: none;
    outline: none;
    background: transparent;
    font-size: 0.14rem;
    color: #333;
}

.send-button {
    background: #1976d2;
    color: white;
    border: none;
    border-radius: 0.2rem;
    padding: 0.08rem 0.2rem;
    font-size: 0.14rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.send-button:hover {
    background: #1565c0;
}

.send-button::after {
    content: "→";
    margin-left: 0.04rem;
}

.quick-replies {
    margin-bottom: 0.12rem;
}

.category-group {
    background: white;
    border-radius: 0.06rem;
    padding: 0.08rem;
    margin-bottom: 0.08rem;
    box-shadow: 0 0.01rem 0.03rem rgba(0, 0, 0, 0.05);
}

.question-category {
    font-size: 0.13rem;
    font-weight: 500;
    color: #1976d2;
    margin-bottom: 0.06rem;
}

.button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.06rem;
}

.button-group button {
    padding: 0.04rem 0.1rem;
    border: 0.01rem solid #e0e0e0;
    border-radius: 0.12rem;
    background: white;
    color: #666;
    font-size: 0.12rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button-group button:hover {
    background: #1976d2;
    color: white;
    border-color: #1976d2;
}

.header-controls {
    display: flex;
    gap: 0.08rem;
}

.control-btn {
    width: 0.24rem;
    height: 0.24rem;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.16rem;
    color: #1976d2;
    background: white;
    transition: all 0.3s ease;
}

.control-btn:hover {
    opacity: 0.9;
    transform: scale(1.1);
}

.control-btn.close:hover {
    background: #ff4444;
    color: white;
}

.chat-minimized {
    position: fixed;
    right: 0.8rem;
    bottom: 0.5rem;
    background: #1976d2;
    color: white;
    padding: 0.12rem 0.24rem;
    border-radius: 0.24rem;
    cursor: pointer;
    box-shadow: 0 0.02rem 0.1rem rgba(0, 0, 0, 0.2);
    z-index: 999;
    transition: all 0.3s ease;
}

.chat-minimized:hover {
    background: #1565c0;
    transform: scale(1.05);
}

@media (max-width: 7.68rem) {
    .chat-container {
        width: 100%;
        height: 100vh;
        margin: 0;
        border-radius: 0;
    }
}

/* 在现有样式的基础上添加表格样式 */
.message-content :deep(table) {
    width: 100%;
    border-collapse: collapse;
    margin: 0.12rem 0;
    background: #fff;
    border-radius: 0.08rem;
    overflow: hidden;
    box-shadow: 0 0.02rem 0.04rem rgba(0, 0, 0, 0.05);
}

.message-content :deep(th),
.message-content :deep(td) {
    padding: 0.08rem 0.12rem;
    text-align: left;
    border-bottom: 0.01rem solid #eee;
    font-size: 0.13rem;
}

.message-content :deep(th) {
    background: #f8f9fa;
    font-weight: 500;
    color: #1976d2;
    white-space: nowrap;
}

.message-content :deep(tr:last-child) td {
    border-bottom: none;
}

.message-content :deep(tr:hover) td {
    background: #f5f9ff;
}

.message-content :deep(td:nth-child(3)) {
    color: #f44336;
    font-weight: 500;
}
.message-content :deep p {
    margin: .05rem 0;
    /* font-weight: 550; */
}
.message-content :deep ol li {
    padding: 0 0 0 .15rem;
}
</style>